<template>
    <b-container>
        <b-form @submit="onSubmit">
            <b-form-group
                id="input-group-3"
                label="Neues Passwort:"
                label-for="input-3"
            >
                <b-form-input
                    id="input-3"
                    v-model="credentials.password"
                    type="password"
                    placeholder="Neues Passwort"
                    required
                ></b-form-input>
            </b-form-group>
            <b-form-group
                id="input-group-4"
                label="Neues Passwort bestätigen:"
                label-for="input-4"
            >
                <b-form-input
                    id="input-4"
                    v-model="credentials.passwordConfirm"
                    type="password"
                    placeholder="Bestätigen Sie Ihr neues Passwort"
                    required
                ></b-form-input>
            </b-form-group>
            <b-button
                type="submit"
                variant="primary"
                style="background-color: #dc3545;border-color: #dc3545;"
            >
                <b-icon
                    v-if="loading"
                    class="button-loading-icon"
                    icon="three-dots"
                    animation="cylon"
                    font-scale="1"
                ></b-icon>
                <span v-if="!loading">Passwort ändern</span></b-button
            >
            <b-form-group>
                <div class="alert-container">
                    <b-alert v-if="error" show variant="danger">{{
                        error
                    }}</b-alert>
                </div>
            </b-form-group>
            <b-form-group>
                <div class="alert-container">
                    <b-alert v-if="success" show variant="success"
                        >Das Passwort wurde erfolgreich geändert.</b-alert
                    >
                </div>
            </b-form-group>
        </b-form>
    </b-container>
</template>

<script>
import auth from "@/auth/auth.helpers";

export default {
    data() {
        return {
            success: false,
            isLoading: false,
            passwordHidden: true,
            error: "",
            loading: false,
            credentials: {
                password: null,
                passwordConfirm: null
            }
        };
    },
    methods: {
        onSubmit(event) {
            event.preventDefault();
            this.error = false;
            this.success = false;
            this.loading = true;

            if (
                this.credentials.password === this.credentials.passwordConfirm
            ) {
                auth.resetPassword(this.credentials, callback => {
                    if (callback) {
                        this.success = true;
                    } else {
                        this.error = callback;
                    }
                    this.loading = false;
                });
            } else {
                this.error =
                    "Die eingegebenen Passwörter müssen übereinstimmen.";
                this.loading = false;
            }
        }
    }
};
</script>
